<!-- eslint-disable -->
<template>
  <div class="tutorial h-100 w-100">
    <div class="container h-100">
      <div class="row h-100 pb-5 pt-5 justify-content-center">
        <div class="col-10 col-sm-8 col-md-5 d-flex flex-column justify-content-between">
          <template v-for="(step, index) of steps">
            <transition name="slide-fade">
              <div class="tutorial-main d-flex flex-column align-items-center"
                   v-if="index === currentStep"
              >
                <img class="tutorial__image w-100" :src="step.img" alt="">
                <h2 class="tutorial__title text-center">
                  {{ step.title }}
                </h2>
                <p class="tutorial__text text-center">
                  {{ step.text }}
                </p>
              </div>
            </transition>
          </template>
          <div class="tutorial-footer d-flex flex-column align-items-center">
            <div class="tutorial-steps d-flex align-items-center">
              <div class="step"
                   v-for="(step, index) of steps" :key="index"
                   :class="{'step_active': index === currentStep}"
              ></div>
            </div>
            <button type="button" @click="nextStep" class="btn btn-primary site-btn site-btn_primary">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const ill1 = require('../assets/Illustration_1.svg');
const ill2 = require('../assets/Illustration_2.svg');
const ill3 = require('../assets/Illustration_3.svg');
const ill4 = require('../assets/Illustration_4.svg');
const ill5 = require('../assets/Illustration_5.svg');
const ill6 = require('../assets/Illustration_6.svg');

export default {
  name: 'Tutorial',
  data() {
    return {
      currentStep: 0,
      steps: [
        {
          img: ill1,
          title: 'Math Homework Solver',
          text: 'Solving math problems in one click.',
        },
        {
          img: ill2,
          title: 'Expert Live Chat',
          text: 'Ask for help with any equation, math problem, home task. Our Experts will help you online.',
        },
        {
          img: ill3,
          title: 'Suports All Math Levels',
          text: 'Eeasily solve a task of any level and explain how.',
        },
        {
          img: ill4,
          title: 'How It Works? Upload or Take a Photo',
          text: 'Our Experts will instantly start a chat to help you.',
        },
        {
          img: ill5,
          title: 'Best Online Experts',
          text: 'We attract math tutors that are available for immediate assistance.',
        },
        {
          img: ill6,
          title: 'Stay Prepared 💪',
          text: 'Getting help is easier than you think. Let’s start.',
        },
      ],
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep += 1;
      } else {
        this.$router.push('/mail');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .5s ease;
}

.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.step {
  width: 7px;
  height: 7px;
  background: #E5E5EA;
  border-radius: 50%;
  margin: 0 3px;

  &_active {
    background: #5E5CE6;
  }
}

.tutorial {
  top: 0;
  background: #FFFFFF;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 0;
  }

  &__title {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.01em;
  }

  &__text {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #8E8E93;
  }

  &-steps {
    margin-bottom: 16px;
  }
}
</style>
