<template>
  <div class="mail " style="height: 110%">
    <div class="container h-100">
      <div class="row justify-content-center h-100">
        <div class="col-11 col-sm-5 xol-md-8 col-lg-5 d-flex flex-column justify-content-end py-4">
          <div>
            <h3 class="mail__pretitle m-0">
              Premium status
            </h3>
            <h2 class="mail__title">
              Get full access
            </h2>
            <div class="mail-list">
              <div class="d-flex align-items-center mail-list__item">
                <img src="../assets/step1.svg" class="me-2" alt="">
                Step by step math solver
              </div>
              <div class="d-flex align-items-center mail-list__item">
                <img src="../assets/step2.svg" class="me-2" alt="">
                Unlimited math solutions
              </div>
              <div class="d-flex align-items-center mail-list__item">
                <img src="../assets/step3.svg" class="me-2" alt="">
                All math levels supported
              </div>
            </div>
            <p class="mail__preform-text">
              3 days trial
            </p>
            <p class="mail__preform-text_small">
              Enter your email to get your Math Expert Chat account details!
            </p>
            <div class="mb-3">
              <label for="email" class="form-label">
                Email address
              </label>
              <input ref="emailInput" type="email" class="form-control" id="email"
                     @input="validateEmail($event.target.value)">
              <div ref="alert" class="invalid-feedback fade hide">
                Enter your email please
              </div>
            </div>
            <div class="d-flex flex-column align-items-stretch align-items-md-start mb-3">
              <button type="button" class="btn btn-primary site-btn site-btn_primary disabled"
                      ref="sendBtn"
                      @click="sendEmail">
                Continue
              </button>
            </div>
            <p class="mail__text text-center">
              By continuing you indicate that you've read and agree to our
              <router-link to="/terms">
                Terms & Conditions
              </router-link>
              and
              <router-link to="/privacy">
                Privacy Policy
              </router-link>
              and give your permission to send you an email with content.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import * as Processing from 'processing';

export default {
  name: 'Mail',
  methods: {
    validateEmail(inputValue) {
      function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

      if (validateEmail(inputValue?.trim())) {
        this.$refs.emailInput.classList.remove('is-invalid');
        this.$refs.sendBtn.classList.remove('disabled');
        this.$refs.alert.classList.replace('show', 'hide');
      } else {
        this.$refs.emailInput.classList.add('is-invalid');
        this.$refs.sendBtn.classList.add('disabled');
        this.$refs.alert.classList.replace('hide', 'show');
      }
    },
    getProductId() {
      return Processing.Processing.createOrder(
        this.$refs.emailInput.value?.trim(),
        this.$store.state.event_id,
        this.$store.state.landing_uuid,
      );
    },
    sendEmail() {
      if (this.$refs.emailInput.value?.trim()) {
        this.getProductId()
          .then(() => {
            this.$router.push('/plans')
              .catch(() => {
              });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mail {
  background-image: url("../assets/Illustration_background.png");
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;

  &__pretitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  &__title {
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #5856D6;
    margin-bottom: 24px;
  }

  &-list {
    margin-bottom: 72px;

    &__item {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: -0.02em;
      margin-bottom: 12px;
    }
  }

  &__preform-text {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
  }

  &__preform-text_small {
    font-size: 17px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #8E8E93;
  }

  &__text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 500px) {
  .mail {
    background-size: cover;
  }
}

@media screen and (min-width: 720px) {
  .mail {
    background-image: url('../assets/BG desktop - 720.png');
    background-size: contain;
  }
}

@media screen and (min-width: 1200px) {
  .mail {
    background-image: url('../assets/BG desktop - 1200.png');
    @media screen and  (max-height: 1000px){
      background-size: cover;
    }
  }
}

@media screen and (min-width: 1920px) {
  .mail {
    background-size: cover;
  }
}
</style>
